import React, { useEffect, useState } from 'react'
import MainArea from '../components/MainArea'
import Navbar from '../components/Navbar'
import NewsLetterPopup from '../components/NewsLetterPopup'
import SideBar from '../components/SideBar'

function Home() {
    const [newsLetter, setNewsLetter] = useState(false)
    const [drawer, setDrawer] = useState(false)
    const menuRef = React.useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setDrawer(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);
    return (
        <div className='bg-[#0D0D0D] md:px-6 md:py-4 h-screen overflow-hidden  grid grid-cols-1 xl:grid-cols-12 gap-4 px-2 py-2'>
            <div ref={menuRef} className={`xl:col-span-3 2xl:col-span-2 xl:h-full xl:flex xl:static absolute  ${drawer ? 'left-0': '-left-[999px]'} z-[9999] w-[320px] xl:w-auto overflow-hidden transition-all ease-in duration-150`}>
                <SideBar setNewsLetter={setNewsLetter} setDrawer={setDrawer}/>
            </div>

            <div className='w-full h-full flex flex-col gap-4 xl:col-span-9  2xl:col-span-10'>
                <Navbar setDrawer={setDrawer}/>
                <MainArea/>
            </div>

            {newsLetter && <NewsLetterPopup setNewsLetter={setNewsLetter}/>}
        </div>
    )
}

export default Home

import React from 'react'

function ContactUs() {
  return (
    <div className={`  w-full bg-cover bg-no-repeat bg-center h-screen relative overflow-hidden`}>
        <img src={require('../assets/contact us page.png')} className='object-contain size-full inset-0'/>
    </div>
  )
}

export default ContactUs

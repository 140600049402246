import React from 'react';

function AboutM220() {
    const videoId = "RFhyJ6Khops"; // Replace with your YouTube video ID

    return (
      <div className="fixed inset-0 w-full h-full overflow-hidden z-[-1]">
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0&controls=0&loop=1&vq=hd2160&playlist=${videoId}`}
          title="YouTube Video" 
          allow="autoplay; encrypted-media; fullscreen"
          allowFullScreen
          className="w-full h-full"
        ></iframe>
        </div>
    )
}

export default AboutM220

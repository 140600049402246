import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import AboutM220 from './pages/AboutM220'
import ContactUs from './pages/ContactUs'
import Home from './pages/Home'
import M220 from './pages/M220'
import Opportunities from './pages/Opportunities'
import WatchVideo from './pages/WatchVideo'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path='/m2-20' element={<M220 />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/about-m2-20' element={<AboutM220 />} />
        <Route path='/watch-video' element={<WatchVideo />} />
        <Route path='/opportunities' element={<Opportunities />} />
      </Routes>
      
      <ToastContainer transition={Zoom} position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
  
    </BrowserRouter>
  )
}

export default App

import React from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function MainArea() {
  return (
      <div className='w-full h-full rounded-2xl overflow-hidden relative' style={{ background: "linear-gradient(72deg, #313130 0%, #3c3c3a 50%)" }}>
          <Swiper
                spaceBetween={50}
                slidesPerView={1}
              modules={[Autoplay]} 
                autoplay={{
                    delay: 6000,  
                    disableOnInteraction: false,  
                    }}  
                    
                >
              <SwiperSlide className='z-50'>
                  <div className='min-h-[calc(100vh-80px)] md:min-h-[calc(100vh-120px)]'>
                  <a className='bottom-8 right-8 absolute z-50' target='_blank' href="https://www.eventbrite.com/e/100-ways-to-generate-and-earn-income-on-the-blockchain-in-2025-tickets-1097509764249?aff=oddtdtcreator">
                          <img className="h-10 md:h-20 object-contain" src={require('../assets/bottom-logo.png')} alt='bottom-logo.png' />
                      </a>
                  <img src={require('../assets/bg.png')} className='select-none absolute inset-0 size-full object-cover object-center' />
                    <div className='home-text md:flex hidden absolute adjust-top w-full right-0 h-[450px]'>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-40 right-1 -rotate-[19deg]'>
                            The M2-20 Blockchain Asset Generator pioneering product combines the strengths of Blockchain, Web3, and Al, crafting a
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[185px] right-1 -rotate-[19deg]'>
                             comprehensive mobile technology developed to serve businesses and individuals with
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[118px] right-1 -rotate-[19deg]'>
                            cryptocurrency asset liquidity daily by mining multiple cryptocurrencies simultaneously, with transactional
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[108px] right-1.5 -rotate-[19deg]'>
                            sending of mined assets to multiple wallets if desired. This trailblazing solution offers key
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[100px] right-2 -rotate-[19deg]'>
                            benefits, including enhanced security, transparency, decentralization, and
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[95px] right-2.5 -rotate-[19deg]'>
                            versatility, all while generating consistent digital asset
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[115px] right-3.5 -rotate-[19deg]'>
                            values for its users.
                        </p>
                    </div>
                    <div className='md:hidden flex absolute bottom-0 w-full right-0 h-[450px]'>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[110px] right-0.5 -rotate-[20deg]'>
                            The M2-20 Blockchain Asset Generator pioneering product combines the strengths of Blockchain, Web3, and
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[154px] right-0 -rotate-[20deg]'>
                        AI, crafting a comprehensive mobile technology developed to serve businesses and 
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[198px] right-0 -rotate-[20deg]'>
                        individuals with cryptocurrency asset liquidity daily by cryptocurrency asset liquidity daily by cryptocurrency
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[242px] right-0 -rotate-[20deg]'>
                        asset liquidity daily by mining multiple cryptocurrencies simultaneously, with transactional sending of mined 
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[284px] right-0 -rotate-[20deg]'>
                        assets to multiple wallets if desired. This trailblazing solution offers key benefits, including enhanced 
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[326px] right-0 -rotate-[20deg]'>
                        security transparency, decentralization, and versatility, all while generating consistent digital asset values for its users.
                        </p>
                      </div>  
                  </div>
                  
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-1.png')} alt='section-slide-1.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-2.png')} alt='section-slide-2.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-3.png')} alt='section-slide-3.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-4.png')} alt='section-slide-4.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-5.png')} alt='section-slide-5.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-6.png')} alt='section-slide-6.png' />
                </div>
              </SwiperSlide>  
            </Swiper>
                   
            </div>
  )
}
